class TeamMore {
  constructor() {
    const $teamBranches = document.querySelectorAll('[data-team-branch]');

    if ($teamBranches === null) {
      return;
    }

    $teamBranches.forEach((branch) => {
      // Open Close
      const $more = branch.querySelector('[data-team-more]');
      if ($more === null) {
        return;
      }

      // remove button when <= 4 items

      if (
        branch.querySelectorAll('[data-team-member]').length === 1
        && Math.max(document.documentElement.clientWidth, window.innerWidth || 0) < 768
      ) {
        $more.remove();
      } else if (
        branch.querySelectorAll('[data-team-member]').length <= 2
        && Math.max(document.documentElement.clientWidth, window.innerWidth || 0) >= 768
        && Math.max(document.documentElement.clientWidth, window.innerWidth || 0) < 1024
      ) {
        $more.remove();
      } else if (
        branch.querySelectorAll('[data-team-member]').length <= 4
        && Math.max(document.documentElement.clientWidth, window.innerWidth || 0) >= 1024
      ) {
        $more.remove();
      }

      $more.addEventListener('click', (e) => {
        // button state
        const $clickEl = e.target.closest('[data-team-more]');
        $clickEl.classList.toggle('-open');

        // change state for this element
        const $parent = e.target.closest('[data-team-branch');
        $parent.querySelectorAll('[data-team-member]').forEach((el) => {
          el.classList.toggle('-open');
        });

        // close all others
        document.querySelectorAll('[data-team-branch]').forEach((b) => {
          if (b !== $parent) {
            b.querySelectorAll('[data-team-member]').forEach((el) => {
              el.classList.remove('-open');
            });
            b.querySelector('[data-team-more]').classList.remove('-open');
          }
        });
      });
    });
  }
}

export default new TeamMore();

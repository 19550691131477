class ReferencesTabs {
  constructor() {
    const $tabs = document.querySelector('[data-tabs]');

    if ($tabs === null) {
      return this;
    }

    $tabs.addEventListener('click', (e) => {
      const $el = e.target.closest('[data-tabs-links]');
      if ($el === null) {
        return;
      }
      $tabs.setAttribute('data-active', $el.getAttribute('data-idx'));
    });
  }
}

export default new ReferencesTabs();

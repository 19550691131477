class MainNavigation {
  constructor() {
    const $nav = document.querySelector('.main-navigation');
    const openClass = '-open';
    const subClass = 'submenu';
    // const titleClass = 'title';

    // Navigation not found
    if ($nav === null) {
      return;
    }

    // make backbutton
    const $navs = document.querySelectorAll('.main-navigation .nav__item');
    $navs.forEach((nav) => {
      const back = document.createElement('div');
      back.classList.add('back-button');
      nav.appendChild(back);
    });

    $nav.addEventListener('click', (e) => {
      // preventDefault on mobile first hierarchy
      if (e.target.parentNode.classList.contains('nav__item')
        && !e.target.parentNode.classList.contains('-open')
        && this.isMobileMenu()
        && this.hasSubItems(e.target.parentNode)
      ) {
        e.preventDefault();
      }

      const $el = e.target.closest('.nav__item');

      // Not menu item clicked
      if ($el === null) {
        return;
      }

      const $subnavigation = $el.querySelector(`.${subClass}`);

      // subnavigation not found
      if ($subnavigation === null) {
        return;
      }

      $el.classList.add(openClass);
      $subnavigation.classList.add(openClass);
      $nav.classList.add(openClass);

      const $backButton = e.target.closest('.back-button');

      // Not back button clicked
      if ($backButton === null) {
        return;
      }

      $el.classList.remove(openClass);
      $subnavigation.classList.remove(openClass);
      $nav.classList.remove(openClass);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  isMobileMenu() {
    if (Math.max(document.documentElement.clientWidth, window.innerWidth || 0) < 768) {
      return true;
    }
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  hasSubItems(item) {
    if (item.querySelector('.submenu')) {
      return true;
    }
    return false;
  }
}

export default new MainNavigation();

import Plyr from 'plyr';

export default class VideoModal {
  constructor($container) {
    this.$container = $container;
    this.$player = null;
    this.$userInitiated = false;
  }

  init() {
    const $videoStarters = document.querySelectorAll('[data-video-start]');
    const $videoStoppers = document.querySelectorAll('[ data-video-stop]');

    if ($videoStarters.length) {
      this.$player = new Plyr('#video-modal', {
        youtube: {
          noCookie: true,
          playsinline: true,
          crossorigin: true,
        },
      });
      this.$player.on('ready', () => {
        if (!this.iOS()) {
          this.$player.play();
          if (!this.$userInitiated) {
            this.$player.stop();
          }
        }
      });

      $videoStarters.forEach((videoStart) => {
        videoStart.addEventListener('click', (e) => {
          e.preventDefault();
          e.stopPropagation();
          this.$userInitiated = true;
          this.$container.classList.remove('-closed');
          this.$player.source = {
            type: 'video',
            sources: [
              {
                src: e.target.dataset.videoStart,
                provider: 'youtube',
                noCookie: true,
                playsinline: true,
                crossorigin: true,
              },
            ],
          };
        });
      });

      $videoStoppers.forEach((videoStopper) => {
        videoStopper.addEventListener('click', (e) => {
          e.preventDefault();
          e.stopPropagation();
          this.$player.stop();
          this.$container.classList.add('-closed');
        });
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
  }
}

class SiteHeader {
  constructor() {
    this.$hamburger = document.querySelector('[data-hamburger-button]');
    this.$siteHeader = document.querySelector('.site-header');

    if (this.$hamburger === null || this.$siteHeader === null) {
      return;
    }

    const $html = document.documentElement;
    const $body = document.body;

    this.$hamburger.addEventListener('click', () => {
      this.$hamburger.classList.toggle('-open');
      this.$siteHeader.classList.toggle('-open');
      [$html, $body].forEach(($el) => $el.classList.toggle('navigation-open'));

      if (this.$siteHeader.classList.contains('-open')) {
        const logindropdown = document.querySelector('[data-dropdown-list]');
        logindropdown.classList.remove('-open');
      }
    });

    // document.addEventListener('click', (e) => {
    //   if ('hamburgerButton' in e.target.dataset || 'hamburgerLabel' in e.target.dataset) {
    //     this.$hamburger.classList.toggle('-open');
    //     this.$siteHeader.classList.toggle('-open');
    //     [$html, $body].forEach(($el) => $el.classList.toggle('navigation-open'));
    //   } else {
    //     this.$hamburger.classList.remove('-open');
    //     this.$siteHeader.classList.remove('-open');
    //     [$html, $body].forEach(($el) => $el.classList.remove('navigation-open'));
    //   }
    // });
  }
}

export default new SiteHeader();

class Tabs {
  constructor() {
    const $tabs = document.querySelectorAll('[data-tabs]');

    $tabs.forEach((tabs) => {
      tabs.addEventListener('click', (e) => {
        const $el = e.target.closest('[data-tabs-links]');
        if ($el === null) {
          return;
        }
        tabs.setAttribute('data-active', $el.getAttribute('data-idx'));
      });
    });
  }
}

export default new Tabs();

import { createApp } from 'petite-vue';
import './scripts/ListFilter';
import './scripts/MainNavigation';
import './scripts/SiteHeader';
import './scripts/SlimSelect';
import './scripts/SpecialCharacterFilter';
import './scripts/Tabs';
import './scripts/TeamMore';
import './scripts/ReferencesTabs';
import { VueRelay } from './scripts/VueRelay';
import './styles/styles.scss';
import 'focus-visible';
import './scripts/VideoModal';
import './scripts/ModuleModal';

const vueComponents = [
  ['taxation-of-lump-sum-payment-calculator', () => import('./scripts/TaxationOfLumpSumPaymentCalculator.vue')],
  ['pension-fund-purchase-calculator', () => import('./scripts/PensionFundPurchaseCalculator.vue')],
  ['tax-at-source-calculator', () => import('./scripts/TaxAtSourceCalculator.vue')],
];

const customComponents = [
  ['.js-contact-form', () => import(/* webpackChunkName: "form" */ './scripts/Form')],
  ['body', () => import(/* webpackChunkName: "SpecialCharacterFilter" */ './scripts/SpecialCharacterFilter')],
  ['[data-site-header]', () => import(/* webpackChunkName: "Header" */ './scripts/Header')],
  ['[data-dropdown-container]', () => import(/* webpackChunkName: "Header" */ './scripts/Dropdown')],
  ['[data-cookie-banner]', () => import(/* webpackChunkName: "CookieBanner" */ './scripts/CookieBanner')],
  ['[data-tooltip]', () => import(/* webpackChunkName: "tooltip" */ './scripts/ToolTip')],
  ['[data-table]', () => import(/* webpackChunkName: "tooltip" */ './scripts/Table')],
  ['[data-product-teaser-new]', () => import(/* webpackChunkName: "ProductTeaser" */ './scripts/ProductTeaser')],
  ['[data-video-modal]', () => import(/* webpackChunkName: "ProductTeaser" */ './scripts/VideoModal')],
  ['[data-module-modal]', () => import(/* webpackChunkName: "ProductBasementTeaser" */ './scripts/ModuleModal')],
  ['body', () => import(/* webpackChunkName: "servicecard-list" */ './scripts/Inviewport')],
];

// PetiteVue Components
const petiteVueComponents = [
  ['[data-petite-vue-component-modal]', () => import('@/petiteVue/Modal'), 'Modal'],
  ['[data-petite-vue-component-plyr-video]', () => import('@/petiteVue/PlyrVideo'), 'PlyrVideo'],
];

(() => {
  async function onReady() {
    vueComponents.forEach(([tag, fn]) => VueRelay.parse(tag, fn));

    // Initialize all custom components…
    customComponents.forEach(([tag, fn]) => {
      const $els = [...document.querySelectorAll(tag)];

      if ($els.length < 1) {
        return;
      }

      (async () => {
        const Constructor = (await fn()).default;

        $els.map(($el) => (new Constructor($el)).init());
      })();
    });

    // create a set of required components
    const componentsSet = new Set();
    petiteVueComponents.forEach(([tag, fn, name]) => {
      const $els = [...document.querySelectorAll(tag)];
      if ($els.length < 1) {
        return;
      }
      componentsSet.add({ name, fn });
    });

    // create a petite-vue instance and register all required components
    const components = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const { name, fn } of componentsSet.values()) {
      // eslint-disable-next-line no-await-in-loop
      components[name] = (await fn()).default;
    }
    createApp(components).mount();

    return this;
  }
  window.addEventListener('load', () => {
    onReady();
  });
})();

import Vue from 'vue';

export class VueRelay {
  static parse(tag, component) {
    return ([...document.querySelectorAll(tag)] || []).map(($el) => new Vue({
      el: $el,

      // @see https://vuejs.org/v2/guide/render-function.html#createElement-Arguments
      render: (createElement) => createElement(
        component,
        { class: $el.className, ...JSON.parse($el.dataset.vue || '{}') },
        $el.innerHTML,
      ),
    }));
  }
}

export default VueRelay;

export default class SpecialCharacterFilter {
  constructor($container) {
    this.$container = $container;
  }
  // eslint-disable-next-line lines-between-class-members
  init() {
    if (!this.$container) {
      return;
    }
    const lang = document.getElementsByTagName('html')[0].getAttribute('lang');
    if (lang !== 'fr') {
      return;
    }
    this.replaceOnDocument(/[\s][?]+/, '\u00A0?', this.$container);
    this.replaceOnDocument(/[\s][!]+/, '\u00A0!', this.$container);
  }
  // eslint-disable-next-line lines-between-class-members
  replaceOnDocument(pattern, string, { target = document.body } = {}) {
    [target, ...target.querySelectorAll('*:not(script):not(noscript):not(style)')]
      .forEach(({ childNodes: [...nodes] }) => nodes
        .filter(({ nodeType }) => nodeType === document.TEXT_NODE)
        .forEach((textNode) => {
          // eslint-disable-next-line no-param-reassign
          textNode.textContent = textNode.textContent.replace(pattern, string);
        }));
    return this;
  }
}

export default class ModuleModal {
  constructor($container) {
    this.$container = $container;
    this.$recalculateTimeout = null;
  }

  init() {
    const $html = document.querySelector('html');
    const $moduleStarterId = `${this.$container.id}-starter`;
    const $moduleStarters = document.getElementById($moduleStarterId).querySelectorAll('[data-module-start]');
    const $moduleStoppers = document.getElementById(this.$container.id).querySelectorAll('[ data-module-stop]');
    const $moduleElements = document.getElementById(this.$container.id).querySelectorAll('[ data-module-index]');

    const hideAllModuleElements = () => {
      $moduleElements.forEach((moduleEl) => {
        moduleEl.classList.add('-hidden');
      });
    };

    if ($moduleStarters.length) {
      $moduleStarters.forEach((moduleStart) => {
        moduleStart.addEventListener('click', (e) => {
          e.preventDefault();
          e.stopPropagation();
          const $moduleEl = document.querySelector(
            `[data-module-index="${moduleStart.dataset.moduleStart}"]`,
          );
          $html.classList.add('-o--hidden');
          this.$container.classList.remove('-closed');
          $moduleEl.classList.remove('-hidden');
          this.$recalculateTimeout = setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
          }, 300);
        });
      });

      $moduleStoppers.forEach((moduleStopper) => {
        moduleStopper.addEventListener('click', (e) => {
          e.preventDefault();
          e.stopPropagation();
          $html.classList.remove('-o--hidden');
          this.$container.classList.add('-closed');
          hideAllModuleElements();
          clearTimeout(this.$recalculateTimeout);
        });
      });
    }
  }
}

import SlimSelect from 'slim-select';

export default new (class {
  constructor(overrides = {}) {
    this.options = {
      selector: '.form-control > select',
      ...overrides,
    };

    this.observer = new MutationObserver((...args) => this.onChange(...args));

    this.observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  }

  onChange() {
    [...document.querySelectorAll(this.options.selector)].forEach(($el) => {
      if ($el.dataset.ssid !== undefined) {
        return;
      }

      // eslint-disable-next-line no-new
      new SlimSelect({
        select: $el,
        showSearch: false,
        showContent: 'bottom',
        ...(JSON.parse($el.dataset.slim || '{}')),
      });
    });

    return this;
  }
})();

class MainNavigation {
  constructor() {
    const $filter = document.querySelector('[data-filter]');
    const openClass = '-open';

    // Navigation not found
    if ($filter === null) {
      return;
    }

    const $button = $filter.querySelector('[data-filter-button]');
    // const $list = $filter.querySelector('[data-filter-list]');

    if ($button === null) {
      return;
    }

    $button.addEventListener('click', () => {
      $filter.classList.toggle(openClass);
    });
  }
}

export default new MainNavigation();
